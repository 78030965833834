import { useTranslator } from "components/Translator"
import { StarRegular } from "components/Icon"
import { LabelWithIcon } from "components/LabelWithIcon"
import classNames from "classnames"
import * as User from "domains/user/model"

export const UserNbReviews = ({ user }) => {
  const translator = useTranslator()

  if (user.nbReviews === null || user.nbReviews === undefined) {
    return null
  }

  return (
    <LabelWithIcon
      icon={
        <StarRegular
          className={classNames("w-4", {
            "text-primary-default": !User.isPro(user),
            "text-primary-light": User.isPro(user),
          })}
        />
      }
      label={translator.trans(
        "UserInfos.reviews",
        { nbReviews: user.nbReviews },
        "components",
      )}
    />
  )
}
