import isNil from "lodash/isNil"
import pick from "lodash/pick"
import * as MaritalStatus from "enums/MaritalStatus"
import * as PlanOrganizationScope from "enums/PlanOrganizationScope"

export const getSearchWords = (user) => {
  return [
    user.firstName ? new RegExp(user.firstName, "i") : null,
    user.lastName ? new RegExp(user.lastName, "i") : null,
    user.mobile ? mkRegexForPhoneNumber(user.mobile) : null,
    user.phone ? mkRegexForPhoneNumber(user.phone) : null,
    user.whatsApp ? mkRegexForPhoneNumber(user.whatsApp) : null,
    user.skype ? new RegExp(escapeRegExp(user.skype)) : null,
  ].filter(Boolean)
}

const escapeRegExp = (string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&")
}

const mkRegexForPhoneNumber = (phoneNumber) => {
  return new RegExp(phoneNumber.nationalNumber.split(" ").join("(\\s|\\.)*"))
}

export const getContactBetweenUsers = (a, b) => {
  const contact = a.contactDetails.find((contact) => contact.user_id === b.id)

  return contact || null
}

export function isExternalUser(user) {
  return user.roles.includes("ROLE_EXTERNAL")
}

export const getPersonalInformations = (user) => {
  const personalInformations = pick(user, [
    "age",
    "maritalStatus",
    "nbChildren",
    "languages",
    "job",
  ])

  if (isNil(personalInformations.age)) {
    delete personalInformations.age
  }

  if (
    isNil(personalInformations.maritalStatus) ||
    (personalInformations.maritalStatus &&
      personalInformations.maritalStatus.enum_value ===
        MaritalStatus.NOT_DISCLOSED)
  ) {
    delete personalInformations.maritalStatus
  }

  if (isNil(personalInformations.nbChildren)) {
    delete personalInformations.nbChildren
  }

  if (
    isNil(personalInformations.languages) ||
    personalInformations.languages.length === 0
  ) {
    delete personalInformations.languages
  }

  if (isNil(personalInformations.job)) {
    delete personalInformations.job
  }

  return personalInformations
}

export const isAdmin = (user) => user.roles.includes("ROLE_ADMIN")

export const isClub = (user) => user.roles.includes("ROLE_CLUB")

export const isOrg = (user) =>
  user.organizationScope?.enum_value === PlanOrganizationScope.ORG

export const isPro = (user) =>
  [PlanOrganizationScope.ORG, PlanOrganizationScope.ASSO].includes(
    user.organizationScope?.enum_value,
  )

export const isExClub = (user) => user.roles.includes("ROLE_EX_CLUB")
