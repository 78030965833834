import { Badge } from "components/Badge"
import { useTranslator } from "components/Translator"
import { UserTypeBadge } from "domains/user/UserTypeBadge"

export const UserBadges = ({ user, size }) => {
  const translator = useTranslator()

  return (
    <>
      <UserTypeBadge user={user} size={size} />
      {user.roles.includes("ROLE_ADMIN") ? (
        <Badge
          label={translator.trans("UserBadges.admin", null, "components")}
          size={size}
        />
      ) : null}
    </>
  )
}
