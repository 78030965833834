import { Check, TimesCircle } from "components/Icon"
import { Tag } from "components/Tag"
import { useTranslator } from "components/Translator"
import { Link } from "components/Link"

export const UserCertifiedTag = ({ user }) => {
  const translator = useTranslator()

  const Icon = user.certified ? Check : TimesCircle

  const label = translator.trans(
    user.certified
      ? "UserCertifiedTag.certified"
      : "UserCertifiedTag.notCertified",
    null,
    "components",
  )

  const color = user.certified ? "success" : "error"

  const href = translator.trans("UserCertifiedTag.href", null, "components")

  return (
    <Tag
      href={href}
      iconStart={<Icon className="w-3" />}
      label={label}
      color={color}
      tag={Link}
      target={"_blank"}
    />
  )
}
