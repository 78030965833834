import { useTranslator } from "components/Translator"
import * as PlanOrganizationScope from "enums/PlanOrganizationScope"
import { Badge } from "components/Badge"
import * as User from "domains/user/model"

export const UserTypeBadge = ({ user, size }) => {
  const translator = useTranslator()
  const label = translator.trans(getLabelKeyForUserType(user))

  return (
    <Badge
      label={label}
      size={size}
      color={User.isPro(user) ? "primary-light" : "primary"}
    />
  )
}

const getLabelKeyForUserType = (user) => {
  if (user.roles.includes("ROLE_CLUB")) {
    return getLabelKeyForClubMember(user)
  }

  if (user.roles.includes("ROLE_EX_CLUB")) {
    return getLabelKeyForExClubMember(user)
  }

  if (user.roles.includes("ROLE_EXTERNAL")) {
    return "UserBadges.external"
  }

  return getLabelKeyForObserver(user)
}

const getLabelKeyForClubMember = (user) => {
  if (PlanOrganizationScope.ASSO === user.organizationScope?.enum_value) {
    return "UserBadges.club.asso"
  } else if (PlanOrganizationScope.ORG === user.organizationScope?.enum_value) {
    return "UserBadges.club.pro"
  }

  return "UserBadges.club.generic"
}

const getLabelKeyForExClubMember = (user) => {
  if (PlanOrganizationScope.ASSO === user.organizationScope?.enum_value) {
    return "UserBadges.exClub.asso"
  } else if (PlanOrganizationScope.ORG === user.organizationScope?.enum_value) {
    return "UserBadges.exClub.pro"
  }

  return "UserBadges.exClub.generic"
}

const getLabelKeyForObserver = (user) => {
  if (PlanOrganizationScope.ASSO === user.organizationScope?.enum_value) {
    return "UserBadges.observer.asso"
  } else if (PlanOrganizationScope.ORG === user.organizationScope?.enum_value) {
    return "UserBadges.observer.pro"
  }

  return "UserBadges.observer.generic"
}
