import { useTranslator } from "components/Translator"

export const UserProfileCompletionRate = ({ user }) => {
  const translator = useTranslator()

  return user.completionRate
    ? translator.trans(
        "UserProfileCompletionRate.label",
        { rate: `${Math.round(user.completionRate)}%` },
        "components",
      )
    : null
}
