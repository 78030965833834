import { useTranslator } from "components/Translator"
import { LabelWithIcon } from "components/LabelWithIcon"
import { SuperNavigator } from "components/Icon"
import classNames from "classnames"
import * as User from "domains/user/model"

export const UserSuper = ({ user }) => {
  const translator = useTranslator()

  if (!user.super || !user.roles.includes("ROLE_CLUB")) {
    return null
  }

  return (
    <LabelWithIcon
      icon={
        <SuperNavigator
          className={classNames("w-4", {
            "text-primary-default": !User.isPro(user),
            "text-primary-light": User.isPro(user),
          })}
        />
      }
      label={
        <div className={"line-clamp-1"}>
          {translator.trans("UserInfos.super", null, "components")}
        </div>
      }
    />
  )
}
