import * as React from "react"
import PropTypes from "prop-types"
import cx from "classnames"

export const InlineList = ({
  children: childrenProps,
  separator: Separator = DefaultSeparator,
}) => {
  const children = React.Children.toArray(childrenProps)
    .filter(Boolean)
    .map((child, index, array) => {
      return React.cloneElement(child, {
        className: cx(child.props.className),
        children: (
          <>
            {child.props.children}
            {index < array.length - 1 && Separator ? <Separator /> : null}
          </>
        ),
      })
    })

  return (
    <div role={"list"} className="flex items-center gap-2 flex-wrap">
      {children}
    </div>
  )
}

InlineList.propTypes = {
  separator: PropTypes.node,
}

const Item = ({ children, className }) => {
  return (
    <div
      role={"listitem"}
      className={cx("flex items-center gap-2 max-w-full", className)}
    >
      {children}
    </div>
  )
}

InlineList.Item = Item

const DefaultSeparator = () => {
  return (
    <div className={"rounded-full size-1 bg-primary-darker shrink-0"}></div>
  )
}
